import React from 'react'
import Image from '../../../../components/images/Image';
import background from '../../../../assets/platform/celu-background-2.jpg';
import { Box, Card, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

const BannerPharma = () => {

    const theme = useTheme()
    const normalSize = useMediaQuery(theme.breakpoints.down('xl'))

    return ( 
        <Box>
            <Card
                sx={{
                    borderRadius:2,
                }}
            >
                <Image src={background} ratio='16/2' />
            </Card>
        </Box>
     );
}
 
export default BannerPharma;