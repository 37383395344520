import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PRODUCT_DISCOUNT_TYPES, TAXES } from '../../../utils/enums';
import { onGetCurrencyValue } from '../../../utils/currencies';


const DiscountChip = (props) => {

    const {
        product,
        small,
        large
    } = props

    const classes = useStyles()
    let contentView = null

    const _product_discount_type_id = product?.product_discount_type_id || product?.sale_discount_type_id

    let fontSize = 'body2'

    if(small){
        fontSize = 'caption'
    }
    if(large){
        fontSize = 'body1'
    }

    if(product?.sale === 0) return null
    

    if(_product_discount_type_id === PRODUCT_DISCOUNT_TYPES.DISCOUNT){
        const sale_discount = parseFloat(product?.sale_discount ?? 0.00)
        const price =  parseFloat(product?.price ?? 0.00)

        let percentage = 0


        if(price > 0){
            percentage = parseInt((sale_discount/price)*100.00)
            contentView = (
                <div className={classes.discount_container}>
                    <Typography variant={fontSize}>{`${percentage}%`}</Typography>
                </div>
            )
        }        
    }

    if(_product_discount_type_id === PRODUCT_DISCOUNT_TYPES.BUNDLE_QXQ){

        const total_digit = (product?.sale_bundle_paid_quantity??0) + (product?.sale_bundle_free_quantity??0)
        const free_digit =  product?.sale_bundle_paid_quantity

        contentView = (
            <div className={classes.discount_container}>
                <Typography variant={fontSize}>{`${total_digit}x${free_digit}`}</Typography>
            </div>
        )   
    }

    if(_product_discount_type_id === PRODUCT_DISCOUNT_TYPES.BUNDLE){
        let subtotal = parseFloat(product?.sale_bundle_price??0.00)*(product?.sale_bundle_quantity??0)
        if(product?.taxable === 1) subtotal = subtotal*(1+TAXES.IVA)

        if(subtotal > 0){
            contentView = (
                <div className={classes.discount_container} style={{padding: small ? '0px 4px' : '2px 8px'}}>
                    <Typography  variant={fontSize}>{`${product?.sale_bundle_quantity??0} x ${onGetCurrencyValue(subtotal,0)}`}</Typography>
                </div>
            )
        }        
    }

    return ( 
        <div>
            {contentView}
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    discount_container:{
        background:theme.palette.secondary.main,
        color:'white',
        padding:'2px 8px',
        borderRadius:16
    }
}))
 
export default DiscountChip;