import React, { useEffect, useState } from 'react';
import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SVGIcon from '../../images/SVGIcon';
import { ORDER_TYPES } from '../../../utils/enums';

import qrIcon from '../../../assets/platform/icons/tags/qr_code.svg'
import abcIcon from '../../../assets/platform/icons/tags/abc.svg'
import { catalogs } from '../../../utils/catalogs';
import MapButton from '../../actions/MapButton';
import { onGetAddressData } from '../../../utils/platform';

const DeliveryContainer = (props) => {

    const {order} = props
    const classes = useStyles()
    const theme = useTheme()

    const [address, setAddress] = useState()


    const shipping_type = catalogs.shipping_types.find(el => el.value === order?.shipping_type_id)??"{}"

    const order_type = order?.order_type_id
    //const 

    useEffect(() => {
        console.log(order)
        setAddress(onGetAddressData(order?.address_data))
    }, [order])

    return ( 
        <div>
            <div>
                <Grid container alignItems='center'>
                    <Grid item xs>
                        <Typography variant='subtitle1'>{address?.name ?? ''}</Typography>
                        <Typography variant='body2'>
                            {`${address?.street ?? ''} ${address?.ext ?? ''}, ${address?.int ?? ''}, ${address?.suburb ?? ''}, ${address?.municipality ?? ''},  ${address?.state ?? ''}.`}
                        </Typography>
                        {order_type === ORDER_TYPES.DELIVERY ? <div style={{marginBottom:8}}>
                            <Typography variant='body2'><strong>Entregar a:</strong> {address?.contact_name ?? '-'}</Typography>
                        </div> : null }
                        {order_type === ORDER_TYPES.DELIVERY ? <div style={{marginBottom:8}}>
                            <Grid container alignItems='center' spacing={1}>
                                <Grid item>
                                    <Typography variant='body2'><strong>Tipo de envío:</strong></Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='subtitle2' color='primary'>{shipping_type?.label ?? ''}</Typography>
                                </Grid>
                                <Grid item>
                                    <div className={classes.icon_container}><div><SVGIcon src={shipping_type?.icon} size={18} color={theme.palette.primary.main} /></div></div>
                                </Grid>
                            </Grid>
                        </div> : null}
                        {order?.require_qr === 1 || order?.require_passphrase === 1 
                        ? <div style={{marginBottom:8}}>
                            <Grid container alignItems='center' spacing={1}>
                                <Grid item>
                                    <Typography variant='body2'><strong>Seguridad:</strong></Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='subtitle2' color='primary'>{order?.require_qr === 1 ? 'QR' : 'Palabra secreta'}</Typography>
                                </Grid>
                                <Grid item>
                                    <div className={classes.icon_container}><div><SVGIcon src={order?.require_qr === 1 ? qrIcon : abcIcon} size={18} color={theme.palette.primary.main} /></div></div>
                                </Grid>
                            </Grid>
                        </div>
                        : null}
                    </Grid>
                    <Grid item>
                        <Stack spacing={1} alignItems='flex-end' >
                            <Typography variant='body2' style={{fontWeight:600}}>{`C.P: ${address?.zip_code}`}</Typography>
                            <MapButton latitude={address?.latitude} longitude={address?.longitude}/>
                        </Stack>
                    </Grid>
                </Grid>
                
            </div>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    icon_container:{
        border:`2px solid ${theme.palette.primary.main}`,
        padding:2,
        borderRadius:6
    }
}))
 
export default DeliveryContainer;