import React, { useRef } from 'react';
import { 
    Grid,
    Box,
    Hidden,
    LinearProgress,
} from '@mui/material';

import PageLayout from '../../../layouts/PageLayout';
import useOrderListPage from './useOrderListPage';
import AddCartModal from '../../../modals/Carts/AddCartModal/AddCartModal';
import { makeStyles } from '@mui/styles';
import CartDetails from './components/CartDetails';
import AddProduct2CartModal from '../../../modals/Carts/AddProduct2CartModal/AddProduct2CartModal';
import TablePaginationCustom from '../../../components/table/TablePaginationCustom';
import EditCartModal from '../../../modals/Carts/EditCartModal/EditCartModal';
import BaseModal from '../../../components/modals/BaseModal';
import TableActionBar from '../../../components/table/TableActionBar';
import AssignUser2CartModal from '../../../modals/Carts/AssignUser2CartModal/AssignUser2CartModal';
import OrderCard from '../../../components/platform/orders/OrderCard';
import { Stack } from '@mui/system';
import OrderDetailCard from '../../../components/platform/orders/OrderDetailCard';
import Iconify from '../../../components/iconify';
import NavigationTab from '../../../components/actions/NavigationTab';
import FileFilterButton from '../../../components/actions/FileFilterButton';
import DateRangePicker from '../../../components/date-range-picker/DateRangePicker';

const TABLE_HEAD = [
    { id: 'id_order', label: 'ID', align: 'left' },
    { id: 'alias', label: 'Etiqueta', align: 'left' },
    { id: 'seller', label: 'Creado por', align: 'left' },
    { id: 'creation_date', label: 'Fecha', align: 'left' },
    //{ id: 'payment_subtotal', label: 'Total', align: 'left' },
    { id: '', label: '', align: '' },
  ];
  

const OrderListPage = () => {

    const classes = useStyles()
    const {actions, data, system, modals, dateRangeManager} = useOrderListPage()
    const {table_config, count, orders, view_order, order} = data
    
    const stackRef = useRef()

    const page = table_config.offset
    const rowsPerPage = table_config.limit

    const isNotFound = !orders.length

    const TABS = [
        {
          value: 'pending',
          label: 'Pendientes',
          icon: <Iconify icon="material-symbols:pending-actions" />,
        },
        {
          value: 'delivered',
          label: 'Completadas',
          icon: <Iconify icon="fluent-mdl2:completed" />,
        },
        {
            value: 'cancel',
            label: 'Canceladas',
            icon: <Iconify icon="material-symbols:cancel-outline-rounded" />,
          },
    ];

 
    return ( 
        <PageLayout
            title='Órdenes'
            title_seo='Órdenes | Celu'
            loading={system.loading}
        >
            <div >
                <DateRangePicker
                    variant="calendar"
                    startDate={dateRangeManager.startDate}
                    endDate={dateRangeManager.endDate}
                    open={modals.date_selector}
                    onChangeStartDate={actions.onChangeStartDate}
                    onChangeEndDate={actions.onChangeEndDate}
                    onClose={() => actions.onChangeModalStatus('date_selector', false)} 

                
                />
                <Hidden lgUp>
                    <OrderModal 
                        open={view_order} 
                        order={order} 
                        onClose={actions.onCloseCartDetails} 
                        loading={system?.order_loading}
                        onAddProduct={() => actions.onChangeModalStatus('add_product_order', true)}
                        onRefresh={actions.onRefreshData}
                    />  
                </Hidden>
                <AssignUser2CartModal 
                    order={order} 
                    open={modals.assign_user_order} 
                    onClose={() => actions.onChangeModalStatus('assign_user_order', false)} 
                    onRefresh={actions.onRefreshData}
                />
                <AddCartModal 
                    open={modals.add_order} 
                    onClose={() => actions.onChangeModalStatus('add_order', false)} 
                    onCompleted={actions.onSelectRow}
                    onRefresh={actions.onRefreshData}
                />
                <EditCartModal 
                    open={modals.edit_order} origin={order}
                    onClose={() => actions.onChangeModalStatus('edit_order', false)} 
                    onRefresh={actions.onRefreshData}
                />
                <AddProduct2CartModal 
                    open={modals.add_product_order} 
                    order={order} 
                    onRefresh={actions.onRefreshData}
                    onClose={() => actions.onChangeModalStatus('add_product_order', false)} 
                />
                
                <Grid container alignItems='center' justifyContent='flex-end' sx={{pb:2}} spacing={2}>
                    <Grid item>
                        <FileFilterButton
                            //isSelected={!!isSelectedValuePicker}
                            startIcon={<Iconify icon="eva:calendar-fill" />}
                            onClick={() => actions.onChangeModalStatus('date_selector', true)}
                            >
                            {dateRangeManager.shortLabel}
                        </FileFilterButton>
                    </Grid>
                </Grid>
                <Grid container direction='row' spacing={2}  >
                    <Grid item xs={12} 
                        lg={view_order ? 5 : 12} 
                        xl={view_order ? 4 : 12}
                    >

                        <NavigationTab options={TABS} value={data.order_type} onChange={actions.onChangeSection}/>
                        <Box sx={{marginBottom:2, mt:2}}>
                            <TableActionBar onRequestSearch={actions.onRequestSearch}/>
                        </Box>
                        {(system.sending) && <Box sx={{marginBottom:2}}><LinearProgress /></Box> }
                        <div >
                            <Stack spacing={2}>
                                {orders.map((row) => (
                                    <OrderCard
                                        order={row}
                                        key={`order-item-${row?.id_order}`}
                                        row={row}
                                        selected={order?.id_order === row?.id_order}
                                    //selected={selected.includes(row.id)}
                                        onSelect={() => actions.onSelectRow(row)}
                                        onDeleteRow={() => actions.onDeleteRow(row)}
                                        onEditRow={actions.onEditRow}
                                    />
                                ))}
                            </Stack>
                            
                        </div>
                        <Box sx={{marginTop:2}}>
                            <TablePaginationCustom
                                tableFilter={table_config}               
                                total={count}
                                onChangePagination={actions.onChangePagination}
                            />
                        </Box>
                    </Grid>
                    <Hidden lgDown>
                        {view_order && 
                        <Grid item lg={7} 
                        xl={8}>
                            
                            <OrderDetailCard 
                                order={order}
                                onClose={actions.onCloseCartDetails} 
                                loading={system?.order_loading}
                                onRefresh={actions.onRefreshData}
                                onAddProduct={() => actions.onChangeModalStatus('add_product_order', true)}
                                onAssignCart2Patient={() => actions.onChangeModalStatus('assign_user_order', true)}
                                /> 
                        
                        </Grid> }
                    </Hidden>
                    
                    
                    
                </Grid>
            </div>
        </PageLayout>
     );
}

const useStyles = makeStyles(theme => ({
    card_list:{
        transition:'1s all linear',
        
    },
    card:{
        overflow:'auto'
    }
}))
 
export default OrderListPage;


const OrderModal = ({
    open, onClose, order, loading, 
    onRefresh,
    onChangeModalStatus,
    onAddProduct}) => {
    return(
        <BaseModal open={open} disableHeader >
            <OrderDetailCard 
                disabledPadding
                order={order}
                onClose={onClose} 
                loading={loading}
                onRefresh={onRefresh}
                onAddProduct={() => onChangeModalStatus('add_product_order', true)}
                onAssignCart2Patient={() => onChangeModalStatus('assign_user_order', true)}
            /> 
        </BaseModal>
    )
    
}