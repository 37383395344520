import { endOfMonth, startOfMonth, subMonths } from "date-fns";
import moment from "moment";
import { useCallback, useEffect, useState } from "react"
import { useAuthContext } from "../../../auth/useAuthContext"
import { useSnackbar } from '../../../components/snackbar';
import useCart from "../../../hooks/api/useCart";
import useOrder from "../../../hooks/api/useOrder";
import useTableSettings from "../../../hooks/useTableSettings";
import { ORDER_STATUS } from "../../../utils/enums";
import { useDateRangePicker } from "../../../components/date-range-picker";

const useOrderListPage = () => {

    const { pharmacy} = useAuthContext()
    const { enqueueSnackbar } = useSnackbar();

    const today = new Date()
    const dateRangeManager = useDateRangePicker(subMonths(startOfMonth(today),3),endOfMonth(today))

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [order_loading, setOrderLoading] = useState(false)
    const [error, setError] = useState(false)
    const [order, setOrder] = useState()
    const [orders, setOrders] = useState([])
    const [count, setCount] = useState(0)
    const [view_order, setViewOrder] = useState(false)
    const [order_type, setOrderType] = useState('pending')


    const [modals, setModals] = useState({
        add_order:false,
        delete_user:false,
        add_product_order:false,
        assign_user_order:false,
        date_selector:false
    })


    const orderManager = useOrder()
    const cartManager = useCart()

    const tableController = useTableSettings({
        initial_filter:{
            search:{key:'alias', value:'', operator:'LIKE'},
            //start_date:{key:'', value:'', operator:'>='},
            //end_date:{key:'', value:'', operator:'<='}
        },
        initial_params:{
            order_by:'id_order',
            order:1,
            limit:25, 
            offset:0,
            start_date:moment().utc().subtract(1,'y').format(),
            end_date:moment().utc().add(1,'y').format()
        }
    })

    useEffect(() => {
        //console.log(pharmacy)
        actions.onInitModule()
    }, [])

    useEffect(() => {
        actions.onRefreshData()
    }, [tableController.pagination, tableController.filter, order_type])

    const onGetParams = useCallback(() => {
        let result = {...tableController.pagination}
        let ANDargs = []

        if(order_type){
            //console.log('OrderType', order_type)
            let ORFilter = []
            if(order_type === 'pending'){
                ORFilter.push({"field":"order_status_id","operator":"<","value":ORDER_STATUS.DELIVERED})
                ORFilter.push({"field":"order_status_id","operator":"=","value":ORDER_STATUS.PICKUP_PENDING})
                ANDargs.push({OR:ORFilter})
            }
            if(order_type === 'delivered'){
                ORFilter.push({"field":"order_status_id","operator":"=","value":ORDER_STATUS.DELIVERED})
                ANDargs.push({OR:ORFilter})
            }
            if(order_type === 'cancel'){
                //ANDargs.push({OR:ORFilter})
                ANDargs.push({"field":"order_status_id","operator":">","value":ORDER_STATUS.DELIVERED})
                ANDargs.push({"field":"order_status_id","operator":"<","value":ORDER_STATUS.PICKUP_PENDING})
            }
            //console.log(order_type, ORFilter)
            
        }


        Object.keys(tableController.filter).forEach(key => {
            const item = tableController.filter[key]
            if(item.value){
                if(item.operator === 'LIKE'){
                    ANDargs.push({field:item.key, operator:item.operator, value:`%${item.value}%`})
                }else{
                    ANDargs.push({field:item.key, operator:item.operator, value:item.value})
                }
            }
        })
        if(ANDargs.length){
            const temp = `${JSON.stringify({"AND":ANDargs})}`
            result.filter = temp
        }
        
        return result
    },[tableController.pagination, order_type, tableController.filter])

    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                const params = onGetParams()
                const _orders = await orderManager.actions.onGetAll(pharmacy?.id_pharmacy, params)
                console.log(_orders)
                setOrders(_orders.data)
                //if(_orders.data.length){
                //    setOrder(_orders.data[0])
                //    setViewOrder(true)
                //}
                setCount(_orders.count)
            } catch (error) {
                console.log(error)
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            setLoading(false)
        },
        onRefreshData: async() => {
            setSending(true)
            try {
                const params = onGetParams()
                const _orders = await orderManager.actions.onGetAll(pharmacy?.id_pharmacy, params)
                setOrders(_orders.data)
                setCount(_orders.count)
                if(order){
                    const _order = await orderManager.actions.onGetOrder(order?.id_order)
                    setOrder(_order)
                }
            } catch (error) {
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            setSending(false)
        },
        onSelectRow: async(row) => {
            setOrderLoading(true)
            setOrder(row)
            setViewOrder(true)
            try {
                const _order = await orderManager.actions.onGetOrder(row?.id_order)
                setOrder(_order)
                //const _cart = await cartManager.actions.on
                
            } catch (error) {
                console.log(error)
            }
            setOrderLoading(false)
        },
        onEditRow: async(row) => {
            setOrderLoading(true)
            setOrder(row)
            try {
                const _order = await orderManager.actions.onGetCart(row?.id_order)
                setOrder(_order)
                actions.onChangeModalStatus('edit_order', true)
            } catch (error) {
                console.log(error)
            }
            setOrderLoading(false)
        },
        onDeleteRow: async(row) => {
            console.log('Emlimianr', row)
            setLoading(true)
            try {
                actions.onChangeModalStatus('delete_user', false)
                await orderManager.actions.onDeleteCart(row.id_order)
                await actions.onRefreshData()
                enqueueSnackbar('Carrito eliminado exitosamente', {variant:"success"})
            } catch (error) {
                console.log(error)
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            setLoading(false)
        },
        onRequestSearch: (value) => {
            console.log(value)
            tableController.onUpdateFilterValue('search', value)
        },
        onCloseCartDetails: () => {
            setViewOrder(false)
            setOrder(null)
        },
        onChangePagination: (_data) =>{
            tableController.setPagination(_data)
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        }, 
        onChangeSection: (val) => {
            //console.log(val)
            setOrderType(val)
        },
        onChangeStartDate: (value) => {
            tableController.onUpdatePaginationValue('offset', 0)
            dateRangeManager.onChangeStartDate(value)
        },
        onChangeEndDate: (value) => {
            tableController.onUpdatePaginationValue('offset', 0)
            dateRangeManager.onChangeEndDate(value)
        }
    }


    const table_config = tableController.pagination

    const system = {loading, sending, error, order_loading}
    const data = {table_config, count, orders, order, view_order, order_type}

    return {
        system, data, actions, modals, dateRangeManager
        
    }
}
 
export default useOrderListPage;