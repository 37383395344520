import { format, getTime, formatDistanceToNow } from 'date-fns';
import moment from 'moment'
// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export const onGetDateFormat = (data, format) => {
  if(!data) return null
  const momentdata = moment(data)
  if(!momentdata.isValid()) return null
  if(format) return momentdata.format(format)
  return momentdata.format('YYYY-MM-DD')
}

export const onGetDateWithTimezone = (data, format) => {
  // This function returns a moment object
  if(!data) return null 
  const temp = moment.utc(data)
  if(!temp.isValid()) return null 
  //console.log(moment.utc(data).format())
  if(!format) return moment(temp.format())

  return moment(temp.format()).format(format)
  
}
