import { generic_server, private_server } from '../../utils/axios';
import { fileNameByUrl, fileTypeByUrl } from '../../utils/files';


const usePharmacy = () => {   

    const actions = {
        onSendCart: async(id, data2send) => {
            let result = null
            await private_server.post(`/pharmacy/send/${id}`, data2send)
        },
        onGetTemplateCountTotal: async(params) => {
            let request = await private_server.get(`/pharmacy/doctor/me/templatecounttotal`, {params:params})
            return request.data.data.template_count
        },
        onGetCartCountTotal: async(params) => {
            let request = await private_server.get(`/pharmacy/doctor/me/cartcounttotal`, {params:params})
            return request.data.data.cart_count
        },
        onGetCartCount: async(params) => {
            let request = await private_server.get(`/pharmacy/doctor/me/cartcount`, {params:params})
            return request.data.data.carta ?? []
        },
        onGetSales: async(params) => {
            let request = await private_server.get(`/pharmacy/me/sales`, {params:params})
            return request.data.data.sales ?? []
        },
        onGetBestProductsQuantity: async(params) => {
            let request = await private_server.get(`/pharmacy/doctor/me/bestproducts/quantity`, {params:params})
            return request.data.data.products ?? []
        },
        onGetMothlySalesQuantity: async(params) => {
            let request = await private_server.get(`/pharmacy/me/salesbymonth`, {params:params})
            return request.data.data.monthly_sales ?? []
        }

    }

    return {
        actions
    }
}
 
export default usePharmacy;