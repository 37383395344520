export const USER_TYPES = {
    ADMIN:1,
}

export const LANGUAGES = {
    SPANISH: 1,
    ENGLISH: 2
}

export const GENDERS = {
    MALE:1,
    FEMALE:2,
    OTHER:3,
    UNKNOWN:4
}

export const CURRENCY = {
    USD: 1,
    MXN: 2
}

export const TAXES = {
    IVA: 0.16
}

export const PRODUCT_DISCOUNT_TYPES = {
    DISCOUNT:1,
    BUNDLE_QXQ:2,
    BUNDLE:3
}

export const ROLES = {
    DOCTOR:2,
    PHARMACY_ASSISTANT: 4
}

export const ORDER_TYPES = {
    DELIVERY:1,
    PICKUP:2,
    COUNTER:3
}

export const PAYMENT_METHODS = {
    CREDIT_CARD:1,
    PAYPAL:2,
    MERCADO:3,
    CASH:4,
    TPV:5
}

export const ORDER_PAYMENT_STATUS = {
    PENDING:1,
    DECLINED:2,
    EXPIRED:3,
    PAID:4,
    REFUNDED:5,
    PARTIALLY_REFUNDED:6,
    CHARGED_BACK:7,
    PRE_AUTHORIZED:8,
    VOIDED:9
}

export const ORDER_STATUS = {
    PENDING_PAYMENT:1,
    PENDING_SHIPMENT:2,
    SHIPPED:3,
    DELIVERED:4,
    CANCELLED:5,
    DISPUTED:6,
    RETURNED:7,
    REIMBURSED:8,
    DELIVERY_ISSUE:9,
    PAYMENT_ISSUE:10,
    PICKUP_PENDING:11
}

export const SHIPPING_TYPES = {
    EXPRESS:2,
    CELUENVIO:5,
    LOCAL:3, 
    NATIONAL:4,
    NONE:1
}